import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";


const BizDevReps = ({pageContext}) => {
    return <Layout
        pageContext={pageContext}
    >
        <Seo title={"Business Development"} />
        <div className="content-row bizdev">
            <div className="seventy">
                <h1>VanWalks Business Opportunities</h1>
                <h2>Premium Content Bundles</h2>
                <p className="sub-title">
                    We&apos;re excited to offer a content partnership with your company. By adding a VanWalks bundle to your existing offerings, we can both make more money! You can easily provide your customer&apos;s email through our web interface or API webhook, making it a seamless process for everyone involved. Let&apos;s work together to provide a unique and engaging experience for your customers.
                </p>
                <h2>Premium Content as Giveaways</h2>
                <p className="sub-title">
                    Are you looking for a unique and valuable giveaway for your customers or employees who are new to Vancouver? Look no further than VanWalks. We offer volume sales of our premium content, which can be easily distributed to your recipients. Our walking tours provide a fun and engaging way to explore the city, with routes that combine historical context, modern events, and local interpretation. Contact us to learn more about how VanWalks can enhance your giveaway program.
                </p>
                <h2>Custom Branded Content or Routes</h2>
                <p className="sub-title">
                    VanWalks is proud to have worked with a variety of organizations to create custom branded content or routes. From the Vancouver Police Museum to the BC Entertainment Hall of Fame, we&apos;ve helped businesses and organizations showcase their unique stories and experiences in Vancouver. Our web-based platform is very nimble and customizable, allowing us to create a one-of-a-kind experience for your customers. Contact us to learn more about our custom branding and route options.
                </p>
                <h1>Let&apos;s Talk About Your Unique Needs</h1>
                <p className="sub-title">
                    At VanWalks, we love a challenge. If you have a unique idea for how our platform can be utilized, let&apos;s talk. Our team is always excited to brainstorm and work with businesses and organizations to create something special. Email us at <a href="mailto:info@vanwalks.ca">info@vanwalks.ca</a> to discuss your ideas and see how VanWalks can help you stand out.
                </p>
            </div>
            <div className="thirty screenshot-bizdev">
                <StaticImage
                    className="screenshot screenshot-1"
                    src="../../images/home_screenshot_1.png"
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                    alt="VanWalks Screenshot"
                    objectFit="contain"
                    placeholder={"none"}
                />
                <StaticImage
                    className="screenshot screenshot-2"
                    src="../../images/home_screenshot_2.png"
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                    alt="VanWalks Screenshot"
                    objectFit="contain"
                    placeholder={"none"}
                />
            </div>
        </div>
    </Layout>
}

export default BizDevReps